import { dictionary } from "../util/appLanguage/dictionary";

export const App_Setting_Mode = "testing"; // production | testing  | localhost
const App_Version_Date = "11.13.2024 3am"; // day.month.year Hour Am/Am
// aaiot_productionBuild_11.13.2024 3am
// aaiot_testingBuild_11.13.2024 3am
// aaiot_clientCode_11.13.2024 3am
export const App_Version = (App_Setting_Mode === "production" ? "P" : "T") + App_Version_Date;
export const App_Name = "aaiotApp";
export const App_ID = 7253851520;

export var App_IsInTestingMode = false;

//#region hooks time
export var App_Login_HoursToCheck = 1 / 60;
export var System_Info_HoursToCheck = 1 / 60;
export var System_Info_Count_HoursToCheck = 5 / 60;
export var Cat_Info_HoursToCheck = 2;
//#endregion


export var App_Server_Url = "https://www.cbmisdb.link/";
export var App_Server_Url_DownloadFiles = "https://tms-cbmis-production.s3.eu-central-1.amazonaws.com/";
// var App_Server_File_Url = "http://localhost:4000/";
export var App_Server_SendEmail_Path = "itcapp/sendmail";


export const App_LocalStorage_Name = "aaoitSystemLocalStorageState";
export var App_LocalStorage_Encrypted_KEY = "2024";
export var App_LocalStorage_Is_Encrypted = true;

if (App_Setting_Mode.toLowerCase() === "testing") {
  App_Server_Url =  "https://www.cbmistesting.link/" // "https://node.louayserver.click/" //
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  App_Login_HoursToCheck = 1 / 60;
  System_Info_HoursToCheck = 1 / 60;
  Cat_Info_HoursToCheck = 2;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
} else if (App_Setting_Mode.toLowerCase() === "localhost") {
  App_Server_Url = "http://localhost:4000/";
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  System_Info_HoursToCheck = 0;
  Cat_Info_HoursToCheck = 0;
  App_Login_HoursToCheck = 0;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
}

export var App_Server_Url_GraphQL = App_Server_Url + "aaiotapp/graphql";
export var App_Server_Url_UploadFiles = App_Server_Url + "aaiotapp/upload/s3";
export var App_Server_Url_SendEmail = App_Server_Url + App_Server_SendEmail_Path;


export var App_Download_URL = "https://static-media-project.s3.eu-central-1.amazonaws.com/private-office-kuwait/";
export const objUploadType = {
  aaiot: { fileType: "aaiot", id: "aaiot", sizeMb: 75, accept: "FILE" },
  video: { fileType: "aaiot", id: "video", sizeMb: 120, accept: "video" },
  announcement: { id: "announcement", fileType: "itc-announcement", sizeMb: 30, accept: "FILE" },

  payment: "payment",
  activates: "activates",
  profile: "profile",
};
  

export const aaiot_System_ID = 2560918876;
export const Cat_Root_ID = 0;

export const Role_Public = 3553430577;
export const objUserRole = {
  "Owner": 1764,
  "Admin": 6517,
  "Employee": 1649,
  "Member": 7125,
  "Itc-Owner": 1742,
  "Itc-Admin": 7840,
  "Itc-Student": 7914,
  "Contractor": 8757,
  "RootAdmin": 7153,
  "Receptionist": 2087,
};

// aaiotApp*-$-*tblMail*-$-*aaiotAppAdd*-$-*3556081947*-$-*2437661919*-$-*8786507229*-$-*1*-$-*Sun, 15 Sep 2024 00:32:38 GMT
// aaiotApp*-$-*tblMail*-$-*aaiotAppAdd*-$-*0*-$-*3553430577*-$-*8786507229*-$-*1
// -------------------------------------------------------------------------------------------------

export const workSectorID = {
  academic: 1773983922,
  gov: 5460695364,
  prvt: 1376840499,
  student: 1773983965,
  unEmployee: 1773983966,
};

export const workSectorIDLabel = {
  1773983922: dictionary?.shared?.app?.academic,
  5460695364: dictionary?.shared?.app?.gov?.title,
  1376840499: dictionary?.shared?.app?.prvt?.title,
  1773983965: dictionary?.shared?.app?.student?.title,
  1773983966: dictionary?.shared?.app?.unEmployee?.title,
};

export const workSectorCat = {
    leader: 1773983901,
    employee: 1773983902,
    manager: 1773983903,
    programer: 1773983904,
    academic: 1773983905,
    student: 1773983906,
};

export const objUserStatus = {
  Approved: 2085578067,
  Rejected: 8715548754,
  Applicant: 2535501102,
};

export const objPartnerStatus = {
  New: 3819315825,
  Approved: 4394019674,
  Rejected: 1124393711,  
};

export const objSupportTransStatus = {
  Send_New: 1,
  Under_Process: 2,
  Approved: 3,
  Rejected: 4,
};

export const objSponserCat ={
  main:{key:"main", number:100},
  golden:{key:"golden", number:50},
  silver:{key:"silver", number:25},
  bronze:{key:"bronze", number:10}
}

export const objTrainingToSupport ={
  fullstack:{id:1, key:"fullstack"},
  manager:{id:2, key:"it-managers"},
  manager_fullstack: { id: 3, key: "manager_fullstack" },
}


export const globalKey = "965+";
export const globalKeyZero = "00965";


export  const countryCodeObj={
  eg: "egypt",
  jo: "jordan",
  kw: "kuwait",
}

export  const countryCodeTrainingTypeObj={
  eg: "fullstack-eg",
  jo: "fullstack-jo",
  kw: "fullstack",
}

export const objQuestionType ={
  trueFalse: 101,
  multipleChoice:102,
  writing:103,
}
export const objQuestionTypeID ={
  101: "trueFalse",
  102:"multipleChoice",
  103:"writing",
}